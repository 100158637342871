<template>
  <div class="content">
    <div class="operate">
      <el-input
        v-model="searchProjectName"
        style="width: 150px; margin-right:10px"
        :placeholder="$t('project.projectNamePlaceholder')"
      />
      <el-button
        type="primary"
        @click="getProjectList"
      >
        {{ $t("common.find") }}
      </el-button>
      <el-button
        type="primary"
        @click="addGroup"
      >
        {{ $t('project.addProject') }}
      </el-button>
      <div class="uploadBox">
        <el-upload
          :headers="{Token:token}"
          :show-file-list="false"
          name="project_file"
          :action="`${url}project_api/upload_project`"
          :on-success="successUpload"
          :file-list="fileList"
        >
          <el-button
            size="small"
            type="primary"
          >
            {{ $t("common.import") }}
          </el-button>
        </el-upload>
      </div>
    </div>
    <div>
      <el-table
        :data="projectList"
        border
      >
        <el-table-column
          prop="project_name"
          label="Project"
          width="250"
        />
        <el-table-column
          prop="stream_number"
          :label="$t('project.streaNnumber')"
          width="240"
          align="center"
        />
        <el-table-column
          prop="stream_summery"
          :label="$t('project.correspond')"
        >
          <template slot-scope="scope">
            <div
              v-for="(item,index) in scope.row.stream_summery"
              :key="index"
            >
              <span>{{ item.group }}</span>/<span>{{ item.profile }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="publish"
          :label="$t('config.isPublish')"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.publish"
              type="success"
            >
              {{ isPublish(scope.row.publish) }}
            </el-tag>
            <el-tag
              v-else
              type="danger"
            >
              {{ isPublish(scope.row.publish) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.operate')"
          width="400"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleDetail(scope.$index, scope.row)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleSend(scope.$index, scope.row)"
            >
              {{ $t("config.send") }}
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleCheck(scope.row)"
            >
              {{ $t("project.check") }}
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="exportProject(scope.row.project_name)"
            >
              {{ $t("common.export") }}
            </el-button>
            <el-button
              v-if="!defaultProject.includes(scope.row.project_name)"
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
            >
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 发布结果详情 -->
    <el-dialog
      :title="$t('editServer.groupSenddetail')"
      :visible.sync="projectResultDialog"
    >
      <el-table
        :data="projectSendResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.sendResult')"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            > {{ releaseFilter(scope.row.success) }} </span>
            <span
              v-else
              style="color: #F56C6C"
            > {{ releaseFilter(scope.row.success) }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="msg"
          :label="$t('editServer.sendDetail')"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="projectResultDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 删除结果展示 -->
    <el-dialog
      :title="$t('editServer.deleteDetail')"
      :visible.sync="projectDeleteDialog"
    >
      <el-table
        :data="deleteResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.sendResult')"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            > {{ releaseFilter(scope.row.success) }} </span>
            <span
              v-else
              style="color: #F56C6C"
            > {{ releaseFilter(scope.row.success) }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="msg"
          :label="$t('common.remark')"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="projectDeleteDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 选择服务器 -->
    <el-dialog
      :title="$t('editServer.chooseServer')"
      :visible.sync="chooseServerDialog"
      width="400px"
    >
      <el-select
        v-model="imanager_server"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="it in serversOptions"
          :key="it.server_ip"
          :value="it"
          :value-key="it.server_ip"
          :label="it.server_ip"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="confirmChooseServer"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 检查结果展示 -->
    <el-dialog
      :title="$t('editServer.deleteDetail')"
      :visible.sync="checkResultDialog"
      width="400px"
    >
      <div v-if="checkResult.check_code == 1">
        {{ $t('editServer.same') }}
      </div>
      <div v-else>
        {{ $t('editServer.notSame') }}
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="checkResultDialog = false"
        >{{ $t("common.confirm") }}</el-button>
        <el-button
          v-if="checkResult.check_code == 0"
          type="primary"
          @click="importProject"
        >{{ $t('editServer.importProject') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_project_list,
  delete_project,
  send_project,
  not_del_projects,
  get_imanager_servers,
  check_project_with_server,
  load_project,
  export_project
} from '@/api/project'
import { zh } from '@/api/code.js'
import { customConfig } from '@/api/request'
import { blobDodnload } from '@/common/util.js'
import { mapMutations, mapGetters, mapState } from 'vuex'
export default {
  data () {
    return {
      searchProjectName: '',
      imanager_server: '',
      chooseServerDialog: false,
      checkResultDialog: false,
      checkResult: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      projectList: [],
      serversOptions: [],
      url: customConfig.baseURL,
      fileList: [],
      projectSendResult: [],
      projectResultDialog: false,
      defaultProject: [],
      projectDeleteDialog: false,
      deleteResult: []
    }
  },
  computed: {
    ...mapState(['currentProject']),
    ...mapGetters(['token'])
  },
  created () {
    this.pageSize = Number(localStorage.projectPageSize) || 10
    this.init()
  },
  methods: {
    ...mapMutations(['updateCurrentProject', 'addToken', 'addSAID']),
    successUpload (res) {
      if (res.code === 1002) {
        this.addToken('')
        this.addSAID('')
        this.$router.push('/login')
        return
      }
      if (localStorage.lang == 'en') {
        if (res.code === 0) {
          this.$message.success(this.$t('common.importSuc'))
          this.getProjectList()
        } else {
          this.$message.error(res.msg)
        }
      } else {
        if (res.code === 0) {
          this.$message.success(this.$t('common.importSuc'))
          this.getProjectList()
        } else {
          this.$message.error(zh[res.code])
        }
      }
    },
    // 导出Project
    async exportProject (project) {
      const res = await export_project({ project })
      blobDodnload(res, 'json')
    },
    async getImanagerServers () {
      const res = await get_imanager_servers()
      if (res.data.code === 0) {
        this.serversOptions = res.data.data
      }
    },
    async importProject () {
      const res = await load_project({
        project_name: this.currentProject,
        server_id: this.imanager_server.id
      })
      if (res.data.code === 0) {
        this.$message.success(this.$t('editServer.importSuc'))
        this.checkResultDialog = false
      }
    },
    // 检查
    handleCheck (row) {
      this.updateCurrentProject(row.project_name)
      this.chooseServerDialog = true
    },
    async confirmChooseServer () {
      if (this.imanager_server) {
        const res = await check_project_with_server({
          project_name: this.currentProject,
          server_id: this.imanager_server.id
        })
        if (res.data.code === 0) {
          this.checkResult = res.data.data
          this.checkResultDialog = true
        }
        this.chooseServerDialog = false
      } else {
        this.$message.error(this.$t('editServer.chooseServerRemind'))
      }
    },
    isPublish (param) {
      if (param) return this.$t('common.yes')
      return this.$t('common.no')
    },
    async getDefaultProject () {
      const res = await not_del_projects()
      if (res.data.code === 0) {
        this.defaultProject = res.data.data
      }
    },
    addGroup () {
      this.$router.push('/project/add_project')
    },
    handleDetail (index, row) {
      this.updateCurrentProject(row.project_name)
      this.$router.push('/project/edit_project')
    },
    releaseFilter (value) {
      if (value) {
        return this.$t('common.succ')
      } else {
        return this.$t('common.fail')
      }
    },
    async handleSend (index, row) {
      const loading = this.$globalLoading(this.$t('editServer.sending'))
      const res = await send_project({ project_name: row.project_name })
      if (res.data.code === 0) {
        this.projectSendResult = res.data.data
        this.projectResultDialog = true
        this.getProjectList()
      }
      loading.close()
    },
    // 删除
    async handleDelete (index, row) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        const loading = this.$globalLoading(this.$t('common.deleteing'))
        delete_project({ project: row.project_name })
          .then((res) => {
            if (res.data.code === 0) {
            // this.$message.success(this.$t("common.deleteSuccess"));
              this.deleteResult = res.data.data
              this.projectDeleteDialog = true
              this.getProjectList()
            }
          })
          .catch(() => {})
          .finally(() => {
            loading.close()
          })
      })
        .catch(() => {})
    },
    handleSizeChange (size) {
      this.pageSize = size
      localStorage.projectPageSize = size
      this.getProjectList()
    },
    handleCurrentChange (current_page) {
      this.currentPage = current_page
      this.getProjectList()
    },
    async getProjectList () {
      const res = await get_project_list({ 
        page_index: this.currentPage, 
        page_size: this.pageSize,
        project_name: this.searchProjectName
      })
      if (res.data.code === 0) {
        this.projectList = res.data.data.project_list
        this.total = res.data.data.project_count
      }
    },
    init () {
      this.getProjectList()
      this.getDefaultProject()
      this.getImanagerServers()
    }
  }
}
</script>

<style lang='scss' scoped>
.content {
  padding-right: 20px;
  margin-top: 20px;
}
.operate {
  display: flex;
  /* border-bottom: 1px solid #DCDFE6; */
  padding-bottom: 10px;
}
.page {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.uploadBox {
  margin-left: 10px;
  display: inline-block;
}
</style>
